import type { App } from 'vue';
import { createApp } from 'vue';
import 'normalize.css/normalize.css';
import { Login } from '@daocloud-proto/ghippo/v1alpha1/login.pb';
import { SecurityPolicy } from '@daocloud-proto/ghippo/v1alpha1/securitypolicy.pb';
import { anakinLogin } from '@/utils/login';
import logout from '@/utils/logout';
import getUserInfo from '@/utils/userInfo';
import getProductListInfo from '@/utils/productListInfo';
import {
  FAVICON, REGISTER_TAB, CHECK_FIRST_TAB, IS_FIRST_LOGIN,
} from '@/utils/constant';
import hasLogged from '@/utils/hasLogged';
import { startCheckSessionTimeout } from '@/utils/sessionTimeout';
import pluginInstall from './plugins';
import { initGlobalStore } from './stores';
import AppElement from './App.vue';

interface RenderProps {
  container?: HTMLElement;
}

let app: App<Element> | undefined;

async function firstOpenLogout() {
  // 不支持 SharedWorker 回落到 sessionStorage 没登陆过就退出逻辑
  if (!SharedWorker) {
    if (!hasLogged.value) {
      return logout();
    }
  }
  const loginWorker = new SharedWorker(new URL('@/workers/login.shared.worker.js', import.meta.url));

  loginWorker.port.start();
  const activeTabInfo = new Promise<void>((resolve) => {
    loginWorker.port.onmessage = async ({ data }) => {
      if (data.type === CHECK_FIRST_TAB) {
        if (!hasLogged.value && data.hasActiveTab === false) {
          await logout();
        } else if (data.hasActiveTab === true) {
          hasLogged.value = true;
        }
      }
      resolve();
    };
  });

  loginWorker.port.postMessage(REGISTER_TAB);

  return activeTabInfo;
}

async function render(props: RenderProps = {}) {
  const { container } = props;

  // 初始化安全策略，不依赖用户信息，可以在获取登录信息前请求
  const logoutPolicy = SecurityPolicy.GetLogoutPolicy({});

  const isFirstLogin = await anakinLogin(Login.OIDCLogin);

  app = createApp(AppElement);
  app.use(pluginInstall);
  app.provide(IS_FIRST_LOGIN, isFirstLogin);

  const { enabled } = await logoutPolicy;

  // 当浏览器关闭需要登出时，打开后没有走过登陆流程强制退出
  // logout需要插件添加fetch中的token
  if (enabled) {
    await firstOpenLogout();
  }
  initGlobalStore();

  // 初始化用户信息需要用到store
  await getUserInfo();
  // 需要在获取用户语言信息后再请求产品信息
  // 不需要阻塞会话超时
  const productListInfo = getProductListInfo();

  startCheckSessionTimeout();

  // 产品信息需要传递给子产品所以需要阻塞父应用加载
  await productListInfo;
  app.mount(container ? container.querySelector('#parent') ?? '#parent' : '#parent');

  return app;
}

const faviconStr = localStorage.getItem(FAVICON);

if (faviconStr) {
  document.querySelector('link[rel*="icon"]')
    ?.setAttribute('href', faviconStr);
}

const html = document.querySelector('html');

if (window.self !== window.top) {
  html?.classList.add('iframe');
}

render();
